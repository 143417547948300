window.AppConfig = {
  CONNECTORS_API_URL: "https://connectors-integration.codat.io",
  USER_MANAGEMENT_URL: "https://users-integration.codat.io",
  IDENTITY_SERVER_URL: "https://identity-integration.codat.io",
  PORTAL_URL: "https://app-integration.codat.io",
  APP_CLIENT_COOKIE_NAME: "app-integration-clientid",
  AMPLITUDE_API_KEY: "1c2a84301c15f56221a6f81af617f5f7",
  AUTH0_CLIENTID: "ZI6Rwgk2imDGDZ82PCQkLHJrZitJODOr",
  AUTH0_DOMAIN: "authentication-integration.codat.io",
  ENVIRONMENT: "Integration",
};
